<template>
  <div class="song-list-page-wrap">
    <div class="sort-wrap">
      <ul>
        <li
          :disabled="item.label_id == label_id"
          class="cate-name"
          v-for="(item, index) in filterData"
          :key="index"
          @click="LoadNewData(item.label_id)"
        >
          {{ item.name }}
        </li>
      </ul>
    </div>
    <div class="warp-content">
      <div class="label_content">
         <a-col
          v-for="item in conentData"
          class="m-bottom-md songwrap"
          :xs="4"
          :sm="4"
          :md="4"
          style=""
          :key="item.playlist_id"
          
        >
          <song-list-item :item="item" isShowCollect @update="updateListItem"></song-list-item>
        </a-col>
      </div>
    </div>
  </div>
</template>
<script>
import SongListItem from '@/pages/home/components/HotMusicList.vue'
export default {
  props: {},
  data () {
    return {
      filterData: [],
      type: 2, //类型
      conentData: [],
      label_id: '' //标签id
    }
  },
  components: {
    SongListItem,
  },

  created () {
    //获取label
    this.$axios.CommonLabels({ type: 2 }).then(res => {
      const data = res.data
      if (data && data.code == 0) {
        this.filterData = data.data ? data.data.list[0].child.slice(0, 6) : []
        this.label_id = this.filterData[0].label_id
        this.$axios
          .Music_Play_List({ label_id: this.label_id, mode: 1, page: 1, sort_type: 2 })
          .then(res => {
            const data = res.data
            if (data && data.code == 0) {
              this.conentData = data.data.list ? data.data.list.slice(0, 10) : []
            }
          })
      }
    })
  },
  computed: {},
  methods: {
    LoadNewData (label_id = '') {
      this.label_id = label_id
      this.$axios
        .Music_Play_List({ label_id: this.label_id, mode: 1, page: 1, sort_type: 2 })
        .then(res => {
          const data = res.data
          if (data && data.code == 0) {
            this.conentData = data.data.list ? data.data.list.slice(0, 10) : []
          }
        })
    },
    updateListItem(scope) {
      for(let key in this.conentData) {
        if(this.conentData[key].playlist_id == scope.playlist_id) {
           this.conentData[key].is_collected = scope.is_collected;
        }
      }
    },
  }
}
</script>
<style lang="scss" scoped>
@media screen and (min-width: $screen-width-md) {
  .song-list-box {
    .ant-spin-container {
      .ng-load-page {
        .ant-row {
          .m-bottom-md {
            .song-list-item {
              .music-img-wrap {
                .img-all-box {
                  height: 194px;
                  padding: 0;
                }
              }
            }
          }
        }
      }
    }
    .ant-list-items {
      .ant-list-item {
        display: inline-block;
        width: 15%;
      }
    }
  }
  .song-list-page-wrap {
    .title {
      height: 88px;
      line-height: 88px;
      //border-bottom: 1px solid $border-color;
    }
  }
  .song-list-box {
    margin-top: 25px;
  }
  .sort-wrap {
    width: 65%;
    margin-top: -30px;
    margin-left: 30%;
    ul {
      width: 100%;
      display: inline-block;
      text-align: right;
    }
    .cate-name:hover {
      color: #ffffff;
      background: #ff7b13;
      background: linear-gradient(270deg, rgb(240, 107, 3) -2%, rgb(234, 39, 224) 100%);
    }
    .cate-name[disabled] {
      color: #ffffff;
      background: #ff7b13;
      background: linear-gradient(270deg, rgb(240, 107, 3) -2%, rgb(234, 39, 224) 100%);
    }
    .cate-name {
      display: inline-block;
      margin-right: 2%;
      height: 34px;
      background: #f8f8f8;
      border-radius: 17px;
      font-size: 13px;
      font-weight: 400;
      color: #333333;
      padding: 8px 14px;
      cursor: pointer;
    }
  }
  .warp-content {
    width: 100%;
    min-height:600px;
    .label_content {
      margin-top: 27px;
      .songwrap{
        width: 18%;
      }
      .songwrap:not(:nth-child(5n)) {
        margin-right:2.5%;
      }
    }
  }
}


//mobile
@media screen and (max-width: $screen-width-md) {
  .song-list-box {
    margin-top: 5px;
  }
  .sort-wrap {
    width: 100%;
    margin: 0;
    margin-top: 3px;
    ul {
      width: 90%;
      text-align: left;
    }
    .cate-name {
      display: inline-block;
      margin-right: 1%;
      height: 18px;
      background: #ff7b13;
      border-radius: 7px;
      font-size: 12px;
      font-weight: 400;
      color: #ffffff;
      padding: 1px 2px;
      cursor: pointer;
    }
    .cate-name[disabled] {
      color: #333333;
      background: #f8f8f8;
    }
  }
  .warp-content {
    width: 100%;
    min-height:380px;
    .label_content {
      margin-top: 27px;
    }
  }
  .songwrap:not(:nth-child(5n)) {
    margin-right:2.5%;
  }
}
</style>
