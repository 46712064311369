<template>
  <div class="company-music-list">
    <div class="music_item" v-for="(item, index) in data" :key="index">
      <router-link :to="`/supplierInfo/${item.user_id}`">
      <a-row style="color:#000;margin-top:10%;">
        <a-col :span=24 style="text-align:center;">
          <img :src="item.profile_photo_url" style="width:128px;height:128px;border-radius:64px;">
        </a-col>
        <a-col :span=24 style="text-align:center;line-height:24px;margin-top: 1%;">
          <span>{{ item.name }}</span>
        </a-col>
        <a-col :span=24 style="text-align:center;line-height:24px;">
          <span class="ellipsis" style="width:95%;display: inline-block;">{{item.desc}}</span>
        </a-col>
      </a-row>
      </router-link>
    </div>
  </div>
</template>
<script>

export default {
  props: {
    data: {
      type: Array
    }
  },
  data () {
    return {}
  },
  components: {
   
  },

  methods: {
  }
}
</script>
<style lang="scss">
  .company-music-list{
    width: 100%;
    .music_item{
      width: 18%;
      height:0;
      padding-bottom: 18%;
      border-radius: 8px;
      display:inline-block;
      background: #F5F6F8;
      cursor:pointer;
      &:hover{
        background: linear-gradient(270deg, rgb(240, 107, 3) -2%, rgb(234, 39, 224) 100%);
        color:#fff;
      }
    }
    .music_item:not(:nth-child(5)) {
      margin-right:2.5%;
    }
  }
</style>
