<template>
  <div class="look-more-box flex cen-center clear" @click="handleMore">
    <div class="more-icon-box">
       <img src="@/assets/images/web/more-default.svg">
    </div>
     <div class="more-icon-box1">
       <img src="@/assets/images/web/more.svg">
    </div>
  </div>
</template>

<script>
  export default {
    data() {
      return {};
    },
    components: {},
    methods: {
      handleMore() {
        this.$emit('handleMore')
      }

    },
  };
</script>

<style lang="scss" scoped>
  .look-more-box {
    display: inline-block;
    width: 5%;
    text-align: right;
    cursor: pointer;
     &:hover{
       .more-icon-box{
          display: none;
       }
       .more-icon-box1{
          display: inline-block;
       }
      }
    .more-icon-box1{
      display: none;
    }
    .more-icon-box{
      display: inline-block;
    }
  }
  //mobile
  @media screen and (max-width: $screen-width-md) {
    .look-more-box {
      width: 100%;
      img{
        width: 50%;
      }
    }
  }
</style>
