<template>
  <div class="fine-music-list-new">
    <div class="music-item">
      <div class="img-all-box-a enlarge" :style="`background-image:url(${item.logo_url})`">
        <router-link :to="`/playListDetail/${item.playlist_id}`">
          <div class="music-play-btn" >
            <icon-font type="iconplay-fff" />
          </div>
          <div class="lable-wrap">
            <span class="lable-item" v-for="lable in item&& item.labels"  :key='lable.id'>{{lable&&lable.name}}</span>
          </div>
            <div
              class="collect-icon"
              :class="{'active': item.is_collected == '1'}"
              @click.prevent="handleCollect(item)"
            >
              <icon-font v-if="item.is_collected == '1'" type="iconheart" />
              <icon-font v-else type="iconheart-empty" />
            </div>
          </router-link>
      </div>
      <div class="music-info">
        <span style="font-weight:700;">{{item.name}}</span>
      </div>
    </div>
  </div>
</template>

<script>
  import musicHandle from '@/mixins/musicHandle'
  export default {
    data() {
      return {

      }
    },
    props: {
      item: {
        type: Object,
        default() {
          return {}
        }
      },
      isShowCollect: {
        type: Boolean,
        default: false,
      },
    },
    mixins: [musicHandle],
    computed: {
    },


    created() {
      this.goodType = 'songlist';

    },

    components: {

    },

    methods: {
      handleCollect(scope) {
        typeof this.goCollectMusic === 'function' && this.goCollectMusic(scope, scope.playlist_id, false);
      },
    }
  }
</script>


<style lang="scss">
  .fine-music-list-new {
      width: 100%;
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      .music-item {
        width: 100%;
        .img-all-box-a{
          width: 100%;
          height: 0;
          padding-bottom:100%;
          position: relative;
          .music-play-btn {
            display:none;
            border-radius: 50%;
            background-color: #fff;
            cursor: pointer;
            opacity: 0.8;
            width: 48px;
            height: 48px;
            font-size: 32px;
            color: #000;
            position: absolute;
            top: 50%;
            left: 50%;
            line-height: 48px;
            transform: translate(-50%, -50%);
            cursor: pointer;
            text-align: center;
            padding:2px 0 0 3px ;
          }
          .lable-wrap{
            position: absolute;
            left: 20px;
            bottom: 20px;
            font-size: 12px;
            font-weight: 400;
            color: #FFFFFF;
            line-height: 20px;
            flex-direction: row;
            span{
              flex: 0 0 44;
              // max-width: 60px;
              overflow: hidden;
              text-overflow:ellipsis;
              white-space: nowrap;
              padding: 0 4px;
              margin:0  8px 4px 0 ;
                border: 1px solid #fff;
                border-radius: 4px;
            }
          }

          .collect-icon {
            position: absolute;
            top: 10px;
            right: 10px;
            font-size: 28px;
            line-height: 1;
            color: #fff;
            &.active {
              color: $primary-color;
            }
          }
         }
         .enlarge{
          border-radius:8px ;
          overflow: hidden;
          transition: all 0.4s;
          -moz-transition:all .4s;
          -webkit-transition:all .4s;
          -o-transition:all .4s;
          background-repeat:no-repeat;   /* 不会重复 */
          background-position: center;   /* 是为了让图片放大的时候从中部放大，不会偏移 */
          background-size: 100%;
          &:hover{
            background-size: 120%;
            .music-play-btn{
              display: inline-block;
            }
          }
        }
        .music-info {
          width: 250px;
          margin-top: 11px;
          overflow: hidden; 
          white-space: nowrap; 
          text-overflow: ellipsis;
          color: #000;
          cursor: pointer;
        }
      }
    }

    @media screen and (max-width: $screen-width-md) {
      .fine-music-list-new .music-item {
        .music-info{
          font-size: 12px;
          width:80%;
        }
      }
    }
</style>
