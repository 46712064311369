<template>
  <div class="hot-wrap back-white">
    <div class="card-carousel">
      <div class="card-carousel-container" :style="transformstyle">
        <div class="card-carousel--card" v-for="(obj, key) in bannerList" :key="key" @click="handleBanner(obj)">
          <img :src="obj.logo_url" :style="card_img_style">
        </div>
      </div>
      <div @click="moveCarousel('right')" class="nav__left" :style="navstyle">
        <img src="@/assets/images/web/iconleft.svg"/>
      </div>
      <div @click="moveCarousel('left')" class="nav__right" :style="navstyle">
        <img src="@/assets/images/web/iconright.svg"/>
      </div>
    </div>

    <div class="search-box-new" style="min-height:320px;background:#f9f9f9;">
        <div>
          <div>
              
            <div style="width:60%;margin:0 20%;display:flex;align-items:center;">
                <div style="width:140px;">
                    <div style="width:124px;height:60px; text-align: left;position:relative;background-color: white;border-top-left-radius:10px;border-bottom-left-radius:10px;cursor: pointer;" id="select" @click="onClickSelect()">
                      <div   style="-moz-user-select: none;-khtml-user-select: none;user-select: none;margin-left:10px;width:80px;position:absolute;line-height: 60px;font-size:18px;">{{xuanze[selectIndex].name}}</div>
                      <!-- <div style="font-size:28px;position:absolute;left:70px;width:20px;height:50px;margin-top:6px;" v-if="onSelectActive">˄</div> -->
                      <img style="font-size:28px;position:absolute;left:90px;width:18px;height:13px;margin-top:25px;-webkit-transform:rotate(180deg);" v-if="onSelectActive" src="@/assets/images/web/home/xiala.svg" />
                      <img style="font-size:28px;position:absolute;left:90px;width:18px;height:13px;margin-top:25px;" v-if="!onSelectActive"  src="@/assets/images/web/home/xiala.svg" />
                      <div style="position:absolute;left:120px;width:1px;height:20px;margin-top:20px;border:1px solid #E2E5E9;"></div>
                    </div>
                    
                    <div class="slider-bg" v-show="onSelectActive" id = "fadeslider">
                      <div class="slider-cell" v-for = "x in xuanze" :key="x.id" @click="onSelect(x.id)" @mouseenter="onPerSelect(x.id)"  name = "fadeslider"
                      :class="{'selectActive':perSelectIndex==x.id,'selectNoActive':perSelectIndex!=x.id}" >{{x.name}}</div>
                    </div>
                  </div>
              <input type="text" v-model="searchstr" placeholder="请输入场景 风格 情绪等关键词" style="width:80%;height:60px;font-size:18px;background:#fff;border:0;outline:none;padding-left:12px;" onfocus="this.placeholder=''" onblur="this.placeholder='请输入场景 风格 情绪等关键词'" @keydown.enter="onSearchValue" /> 
              <div  style="background:#15110F;height:60px;text-align:center;line-height:60px;border-top-right-radius:10px;border-bottom-right-radius:10px;cursor:pointer;width:20%" @click="onSearchValue">
                <img src="@/assets/images/web/home/header_search.svg" />
                <span style="font-size:20px;color:#fff;vertical-align: middle;">  搜索</span>
              </div>
            </div>
          </div>
          <a-row  style="text-align: left;margin-top:30px;font-size:14px;font-weight:500px;">
            <a-col :span="6"></a-col>
            <a-col :span="2" style="text-align:right;color:#909090;">热搜词：</a-col>
            <a-col :span="16">
              <span  v-for="(item, key) in recommanSearch" :key="key">
                <router-link :to="`/allmusic?id=1&q=${item}`" v-if="key < 1"><span  class="text-item-span">{{item}}</span></router-link>
                <router-link :to="`/allmusic?id=1&q=${item}`" v-else style="color:#909090"><span  class="text-item-span">{{item}}</span></router-link>
              </span>
            </a-col>
          </a-row>
          <a-row class="container" style="margin-top:28px;font-weight:500px;text-align:center;">
            <a-col :span="2" style="font-size:14px;" v-for="(item, key) in recommanLabel" :key="key">
              <router-link :to="`/allmusic?id=1&labels=${item.label_id}`"><span class="label-item-span">{{item.name}}</span></router-link>
            </a-col>
          </a-row>
        </div>
    </div>

    <div>
      <!-- 歌单推荐 -->
      <div class="case-wrap container">
        <div class="m-bottom-md cen-space">
          <span class="item">主题歌单</span>
          <span class="item-list">编辑精选,每一首都是万里挑一</span>
          <router-link class="font-color-base" :to="`/playlist`">
            <lookMore></lookMore>
          </router-link>
          <songListHome></songListHome>
        </div>
      </div>

      <!-- 火花精选 -->
      <div class="case-wrap heart-wrap m-bottom-60 container">
        <div class="cen-space m-bottom-md">
          <span class="item">单曲推荐 </span>
          <span class="item-list">至臻选择 每周更新</span>

          <router-link
            class="font-color-base"
            :to="`/playListDetail/${heartSongListId}`"
          >
            <lookMore></lookMore>
          </router-link>
        </div>
        <div class="jingxuan">
          <a-row>
            <a-col :span=6 style="height:64px;margin-bottom:14px;" v-for="(item, index) in heartList" :key="index" >
              <a-col :span=24 class="play_box">
                <a-col style="float:left;width:20%;">
                  <img :src="item.logo_url" style="width:100%;height:100%;border-radius: 4px;"/>
                  <img src="@/assets/images/web/info_play.svg"
                    v-if="!isPlaying(item.music_id)" 
                    @click="onClickIcon(item)"
                    class="playIncon_home"
                  />
                  <img src="@/assets/images/web/pouse.svg"
                  v-else
                  @click="onPause(item.music_id)"
                  class="playIncon_home"  /> 
                </a-col>
                <a-col style="float:left;width:80%;padding-left:12px;white-space: nowrap;overflow: hidden;text-overflow: ellipsis;">
                  <router-link :to="`/musicDetail/${item.music_id}`">
                    <span style="font-weight: 700;color: #333333;font-size:16px;">{{ item.name }}</span>
                  </router-link>
                  <p style="font-weight: 400;color: #313131;font-size:14px;margin-top:3px;">
                    <router-link :to="`/supplierInfo/${item.suplr_id}`">
                      <span style="font-weight: 400;color: #313131;font-size:14px;">{{ item.suplr_name }}</span>
                    </router-link>
                  </p>
                </a-col>
              </a-col>
            </a-col>
          </a-row>
        </div>
      </div>

      <!-- 伙伴推荐 -->
      <div class="case-wrap partner-wrap m-bottom-60 container">
        <span class="item">合作伙伴 </span>
        <span class="item-list">汇集众多优秀音乐制作人 </span>
        <div style="float: right;" class="more-wrap">
          <span style="margin-right:17px;">
            <img
              src="@/assets/images/web/iconleft_close.svg"
              @click="partner_prev()"
              v-if="num <= 0"
              disabled="disabled"
            />
             <img
              v-else
              src="@/assets/images/web/iconleft.svg"
              @click="partner_prev()"
              class="canhover"
            />
          </span>
          <span>
            <img
              src="@/assets/images/web/iconright_close.svg"
              @click="partner_next()"
              disabled="disabled"
              v-if="num >= 5"
            />
            <img
              src="@/assets/images/web/iconright.svg"
              @click="partner_next()"
              v-else
              class="canhover"
            />
          </span>
        </div>

        <div class="partner">
          <PartnerList :data="partnerList"></PartnerList>
        </div>
      </div>

      <!-- 音乐公司 -->
      <div class="case-wrap partner-wrap m-bottom-60 container">
        <span class="item">精选厂牌 </span>
        <span class="item-list">试听更多优秀厂牌作品 </span>
        <div class="partner">
          <CompanyList :data="companyList"></CompanyList>
        </div>
      </div>

      <!-- 精彩案例 -->
      <div class="case-wrap m-bottom-60 container">
        <div class="m-bottom-md">
          <span class="item">精彩案例 </span>
          <span class="item-list">音乐与创意完美结合 </span>
        </div>

        <div class="case-music">
          <CaseList :data="caseList"></CaseList>
        </div>
      </div>
    </div>

    <!-- 购买指引 -->
    <div style="min-height:500px;text-align:center;background:#f9f9f9;">
      <div class="container">
        <a-row>
          <a-col :span="24" style="margin-top:25px;"><span style="font-size:32px;">商用授权保障</span></a-col>
          <a-col :span="24" style="margin-top:10px;"><span style="font-size:20px;color:grey;">- 四部快速获取商用授权 -</span></a-col>
        </a-row>

        <a-row style="margin-top: 50px;">
          <a-col :xs="12" :sm="12" :md="6" :lg="6" :xl="6">
            <a-col :span="24"><img src="@/assets/images/web/online.svg" /></a-col>
            <a-col :span="24"><p style="font-size:24px;font-weight:500;margin-top:30px;">01 在线选曲</p></a-col>
            <a-col :span="24"><p style="font-size:14px;font-weight:500;margin-top:15px;padding: 0 18%;">通过标签、歌单、等方式挑选音乐下载试听版音频文件快速试用</p></a-col>
          </a-col>

          <a-col :xs="12" :sm="12" :md="6" :lg="6" :xl="6">
            <a-col :span="24"><img src="@/assets/images/web/affirm.svg" /></a-col>
            <a-col :span="24"><p style="font-size:24px;font-weight:500;margin-top:30px;">02 确认用途</p></a-col>
            <a-col :span="24"><p style="font-size:14px;font-weight:500;margin-top:15px;padding: 0 18%;">根据项目实际用途确认授权场景降低音乐使用成本</p></a-col>
          </a-col>

          <a-col :xs="12" :sm="12" :md="6" :lg="6" :xl="6">
            <a-col :span="24"><img src="@/assets/images/web/pay.svg" /></a-col>
            <a-col :span="24"><p style="font-size:24px;font-weight:500;margin-top:30px;">03 支付购买</p></a-col>
            <a-col :span="24"><p style="font-size:14px;font-weight:500;margin-top:15px;padding: 0 18%;">支持微信、支付宝、公对公转账付款后可在线申请发票与合同</p></a-col>
          </a-col>

          <a-col :xs="12" :sm="12" :md="6" :lg="6" :xl="6">
            <a-col :span="24"><img src="@/assets/images/web/obtain.svg" /></a-col>
            <a-col :span="24"><p style="font-size:24px;font-weight:500;margin-top:30px;">04 获取证书</p></a-col>
            <a-col :span="24"><p style="font-size:14px;font-weight:500;margin-top:15px;padding: 0 18%;">完善项目授权信息即可获取无水印高清音乐，生成官方授权证书</p></a-col>
          </a-col>
        </a-row>
      </div>
    </div>

    <div class="home-slices hidden-sm" style="height: 440px;background: #000000;color: #FFFFFF;">
      <a-row>
        <a-col :span=15 style="margin: 64px 0;height:312px;">
          <!-- <a-col :span=5></a-col> -->
          <a-col :span=15 style="margin-left:20.83333333%">
            <a-col :span=24>
              <span style="font-size: 320%;font-weight: 400;color: #FFFFFF;width:588px;display:block;">国内最大RF商用音乐供应商</span>
              <span style="display:block;height: 8px;background: #FF7B13;width:588px;margin-top:-21px;"></span>
            </a-col>
            <a-col :span=24 style="margin-top:36px;">
              <span style="font-size: 16px;font-weight: 400;color: #FFFFFF;">火花音悦拥有超60万首全版权、定价权的曲库资源，涵盖RF、RM等多种授权形式，联合国内外数百家版权方和音乐人实时最新音乐作品，结合独家音乐管理系统，为广告、短视频、综艺等行业上百家企业输出企业版、标准版和极速版等定制化企业级音乐商用SaaS服务。
              </span>
            </a-col>
            <a-col :span=24 style="margin-top:20px;font-weight: 500;">
              <a-col :span=6>
                <span style="color: #FF7B13;font-size: 36px;">60万+</span>
                <span style="display:block;font-size: 18px;">原创音乐</span>
              </a-col>
              <a-col :span=6>
                <span style="color: #FF7B13;font-size: 36px;">40万+</span>
                <span style="display:block;font-size: 18px;">累计授权</span>
              </a-col>
              <a-col :span=6>
                <span style="color: #FF7B13;font-size: 36px;">1000+</span>
                <span style="display:block;font-size: 18px;">原创音乐人</span>
              </a-col>
              <a-col :span=6>
                <span style="color: #FF7B13;font-size: 36px;">2万+</span>
                <span style="display:block;font-size: 18px;">合作企业</span>
              </a-col>
            </a-col>
          </a-col>
          <!-- <a-col :span=4></a-col> -->
        </a-col>
        <a-col :span=9 style="margin-top:21px;text-align:right;overflow:hidden;">
          <img src="@/assets/images/web/slices.png" />
        </a-col>
      </a-row>
      
      <!--<div class="fl-left">
        <img src="@/assets/images/web/slices.png" />
      </div>-->
    </div>

    <div class="cooperation">
      <div class="home-con-img">
        <div class="head-name" style="font-size:32px;">合作品牌</div>
        <div class="head-name" style="color:grey;font-size:20px;margin-top:10px;">- 已有上千家企业成为正版音乐的守护者 -</div>
        <img src="@/assets/images/web/cooperative2x.png" alt="" />
      </div>
    </div>
  </div>
</template>
<script>
import CaseList from './components/CaseList.vue'
import PartnerList from './components/PartnerList.vue'
import CompanyList from './components/CompanyList.vue'
import LookMore from './components/LookMore.vue'
import songListHome from './components/SongListHome.vue'
import musicHandle from '@/mixins/musicHandle'

export default {
  title: 'Centered slides + Auto slides perview',

  props: {
    data: {
      type: Array
    }
  },
  data () {
    return {
      num: 0,
      currentOffset: 0,
      card_img_style: "width:373px;height:300px;",
      windowPage: 4,
      paginationFactor: 383,
      transformstyle:"transform: translate3d(0px, 0px, 0px);height:300px;",
      bannerList:[],
      // bannerList2: [
      //   // {
      //   //   url:
      //   //     'https://spark-test-1304044220.cos.ap-beijing.myqcloud.com/assets/common/index_banner/Tiger.png',
      //   //   alt: '福虎迎春丨新年大吉好运来',
      //   //   id: 1,
      //   //   link: 'https://spark.art/playListDetail/7f00f09e9cdd4010001'
      //   // }, 
      //   //  {
      //   //   url:
      //   //     'https://spark-test-1304044220.cos.ap-beijing.myqcloud.com/assets/common/index_banner/yuandan.png',
      //   //   alt: '元旦特辑丨一元复始烘托新年氛围',
      //   //   id: 1,
      //   //   link: 'https://www.spark.art/playListDetail/7f00a8e711a08010001'
      //   // }, 
      //   // {
      //   //   url:
      //   //     'https://spark-test-1304044220.cos.ap-beijing.myqcloud.com/assets/common/index_banner/shengdan.png',
      //   //   alt: '圣诞限定丨温暖小清新的圣诞BGM',
      //   //   id: 1,
      //   //   link: 'https://www.spark.art/playListDetail/7f00e6079f3ff010001'
      //   // },
      //   {
      //     url:
      //       'https://spark-test-1304044220.cos.ap-beijing.myqcloud.com/assets/common/index_banner/fashion%20beauty.png',
      //     alt: '潮流美妆丨专注做女王，认真做自己',
      //     id: 1,
      //     link: 'https://www.spark.art/playListDetail/800089f889f21010001'
      //   },
      //   {
      //     url:
      //       'https://spark-test-1304044220.cos.ap-beijing.myqcloud.com/assets/common/index_banner/zongyi.png',
      //     alt: '综艺配乐丨综艺必备BGM，提升你的Level',
      //     id: 1,
      //     link: 'https://www.spark.art/playListDetail/7f00ba8ba88f8010001'
      //   },
      //   {
      //     url:
      //       'https://spark-test-1304044220.cos.ap-beijing.myqcloud.com/assets/common/index_banner/qiye.png',
      //     alt: '「宣传片」完美塑造企业卓越形象',
      //     id: 2,
      //     link: 'https://www.spark.art/playListDetail/80008c269b8e0010001'
      //   },
      //   {
      //     url:
      //       'https://spark-test-1304044220.cos.ap-beijing.myqcloud.com/assets/common/index_banner/kadian.png',
      //     alt: '「节奏卡点」选择属于你的专属节奏',
      //     id: 3,
      //     link: 'https://www.spark.art/playListDetail/7f00b5ff14b3b010001'
      //   },
      //   {
      //     url:
      //       'https://spark-test-1304044220.cos.ap-beijing.myqcloud.com/assets/common/index_banner/kuaixiao.png',
      //     alt: '「商业广告」潮流快消品，因“乐”价更高',
      //     id: 4,
      //     link: 'https://www.spark.art/playListDetail/7f00a0ca51543010001'
      //   },
      //   {
      //     url:
      //       'https://spark-test-1304044220.cos.ap-beijing.myqcloud.com/assets/common/index_banner/zhongguofeng.png',
      //     alt: '「中国风」千古风华 泱泱华夏',
      //     id: 5,
      //     link: 'https://www.spark.art/playListDetail/7f0094d7469b8010001'
      //   },
      //   {
      //     url:
      //       'https://spark-test-1304044220.cos.ap-beijing.myqcloud.com/assets/common/index_banner/dianying.png',
      //     alt: '「国风电子」古韵融合新潮元素',
      //     id: 6,
      //     link: 'https://www.spark.art/playListDetail/800086258cc7f010001'
      //   },
      //   {
      //     url:
      //       'https://spark-test-1304044220.cos.ap-beijing.myqcloud.com/assets/common/index_banner/katong.png',
      //     alt: '「卡通」轻松欢快的卖萌小调',
      //     id: 7,
      //     link: 'https://www.spark.art/playListDetail/80008b8eac1dc010001'
      //   },
      //   {
      //     url:
      //       'https://spark-test-1304044220.cos.ap-beijing.myqcloud.com/assets/common/index_banner/as.png',
      //     alt: '「霓虹闪耀」日本独立音乐的洒脱之声',
      //     id: 8,
      //     link: 'https://www.spark.art/playListDetail/7f00a982cbf20010001'
      //   },
      // ],
      heartSongListId: '',
      musicians:[],
      partnerList: [],
      companyList: [],
      cooperativePartner: [],
      heartList: [],
      caseList: [],
      searchstr:'',
      recommanSearch:[],
      // recommanSearch2:[
      //   '纪念',
      //   '国庆',
      //   '开心',
      //   '宣传片',
      //   '短视频',
      //   '广告',
      //   '儿童',
      //   '悬疑',
      //   '轻快',
      //   '中国风',
      //   '浪漫',
      // ],
      recommanLabel:[],
      navstyle: "margin-top: -172px;",

      xuanze:[{id:0,name:"全部曲库"},{id:1,name:"会员专区"},{id:2,name:"音效曲库"}],
      onSelectActive:false,
      selectIndex:0,
      perSelectIndex:0,
    }
  },
  components: {
    songListHome,
    CaseList,
    PartnerList,
    CompanyList,
    LookMore
  },
  mixins: [musicHandle],
  created () {
    this.$axios.Music_Fine_Hot().then(({ data }) => {
      if (data.code == 0) {
        this.heartList = data.data.recommend_music.list
        this.heartSongListId = data.data.recommend_music.playlist_id
        this.caseList = data.data.scene_case
        this.musicians = data.data.musicians
        this.partnerList = data.data.musicians.slice(0, 5)
        this.companyList = data.data.companys.slice(0,5)
      }
    });
    this.$axios.Music_Fine_Style().then(res => {
        if (res.data.code == 0) {
            let data = res.data.data;
            this.recommanLabel = data.label_pic;
        }
    });
    this.fixStyle();

    this.$axios.Banner_List().then(res => {
      const data = res.data
      if (data && data.code == 0) {
        this.bannerList = data.data ? data.data : []
        // for (var i=0;i<this.bannerList.length;i++){
        //   var banner = this.bannerList[i];
        //   banner.url2 = "https://spark-test-1304044220.cos.ap-beijing.myqcloud.com/"+banner.url;
        // } 
      }
    })
    this.$axios.Topic_List().then(res => {
      const data = res.data
      if (data && data.code == 0) {
        this.recommanSearch = data.data ? data.data : []        
      }
    })
    window.onresize = () => {
        this.fixStyle();
    }
  },
  mounted() {
    document.addEventListener('click',(e) => {
      const contentWrap = document.getElementById("select");
      if(contentWrap ==null ||  contentWrap.contains(e.target)) return
      // this.onSelectActive = false
      this.close();
    })
  },
  methods: {
    fixStyle(){
      let windowWith = document.documentElement.clientWidth;
      let img_width = (windowWith/this.windowPage)-10;
      let img_height = img_width*0.81;
      this.card_img_style = "width:"+img_width+"px;height:"+img_height+"px";
      this.paginationFactor = img_width + 10;
      let newheight = img_height+ 20;
      this.transformstyle = "transform: translate3d("+this.currentOffset+"px, 0px, 0px);height:"+newheight+"px";
      let navtop = newheight/2+19;
      this.navstyle = "margin-top:-"+navtop+"px";
    },
    isPlaying (now_id) {
      let state = this.$store.state
      if (!state.globalVideoInfo) return false
      let { music_id, url } = state.globalVideoInfo
      return (
        state.globalVideoPlaying &&
        music_id &&
        url &&
        now_id === music_id
      )
    },
    partner_next () {
      this.num = 5;
      let data = this.musicians;
      this.partnerList = data.slice(5, 10);
    },
    partner_prev () {
      this.num = 0;
      let data = this.musicians;
      this.partnerList = data.slice(0, 5);
    },
    onClickIcon (item) {
      this.isPlaying(item.music_id) ? this.onPause() : this.onPlay(item)
      this.$store.commit('changeVideoList', this.list || [])
    },
    onPlay (item) {
      let musicInfo = item
      musicInfo.url = musicInfo.audition_url
      this.$store.commit('changeVideoInfo', item)
      this.$nextTick(() => {
        this.$store.commit('changeVideoPlaying', true)
      })
      this.goPlayMusic(musicInfo)
      this.play_music_id = musicInfo.music_id;
    },
    onPause () {
      this.$store.commit('changeVideoPlaying', false)
    },
    moveCarousel (type) {
      let len = this.bannerList.length;
      let windowWith = document.documentElement.clientWidth;
      let maxFactor = -(len - this.windowPage ) * this.paginationFactor;
      if(type == 'left') {
        if( (this.currentOffset ) > maxFactor) {
          this.currentOffset -= this.paginationFactor;
        }
      } else {
        if((this.currentOffset) < 0) {
          this.currentOffset += this.paginationFactor;
        }
      }
      let img_width = (windowWith/this.windowPage)-10;
      let img_height = img_width*0.81;
      let newheight = img_height+ 20;
      this.transformstyle = "transform: translate3d("+this.currentOffset+"px, 0px, 0px);height:"+newheight+"px";
    },
    handleBanner (item) {
      let link = item.link
      if (!item.link) {
        link = this.bannerList[item - 1].link
      }
      window.open(link, '_blank')
    },
    onSearchValue(){
      if(this.selectIndex == 0)
      {
        this.$router.push({
            path: encodeURI(`/allmusic?id=1&q=${this.searchstr}`)
        })
      }else if(this.selectIndex == 1)
      {
        this.$router.push({
            path: encodeURI(`/vipmusic?id=7&q=${this.searchstr}`)
        })
      }else if(this.selectIndex == 2)
      {
        this.$router.push({
            path: encodeURI(`/allSound?id=6&q=${this.searchstr}`)
        })
      }
      
    },
    onClickSelect(){
      // this.onSelectActive = !this.onSelectActive;
      if(!this.onSelectActive){
        this.onSelectActive = true;
        this.open();
      }else{
        this.close();
      }
    },
    onSelect(index){
      // console.log(id)
      this.selectIndex= index;
      this.perSelectIndex = index;
    },
    onPerSelect(index){
      this.perSelectIndex = index;
    },
    open(){
      var elems =  document.getElementsByName("fadeslider"); 
      var ele = document.getElementById("fadeslider"); 
      var count = 0;
      var id = setInterval(frame, 5);
      var opacity = 0.4;
      var pos = 0;
      ele.style.marginTop = pos+'px';
      ele.style.opacity=1;
      for(var elem of elems) {
        elem.style.opacity=opacity;
      }
      function frame() {
          if (count ==  20) {
              clearInterval(id);
          } else {
              count++; 
              pos += 1;
              pos = Math.min(pos, 6);
              ele.style.marginTop = pos + 'px'; 
              opacity+=0.03;
              // ele.style.opacity=opacity;
              for(var elem of elems) {
                elem.style.opacity=opacity;
              }
          }
      }

    },
    close(){
      var that = this;
      var elems =  document.getElementsByName("fadeslider"); 
      var ele = document.getElementById("fadeslider"); 
      var count = 0;
      var id = setInterval(frame, 5);
      var opacity = 1;
      var pos = 6;
      ele.style.marginTop = pos+'px';
      ele.style.opacity=1;
      for(var elem of elems) {
        elem.style.opacity=opacity;
      }
      function frame() {
          if (count ==  20) {
              clearInterval(id);
              that.onSelectActive = false;
          } else {
              count++; 
              pos -= 1;
              pos = Math.max(pos, 0);
              ele.style.marginTop = pos + 'px'; 
              opacity-=0.03;
              // ele.style.opacity=opacity;
              for(var elem of elems) {
                elem.style.opacity=opacity;
              }
          }
      }
    },

  }
}
</script>
<style lang="scss">
.search-box-new{
  padding-top: 60px;
  .text-item{
    cursor:pointer;
  }
  .text-item-span{
    margin-left: 2%;
    font-size: 14px;
  }
  .label-item-span{
    width:calc(100% - 5px);
    line-height:36px;
    display:inline-block;
    text-align:center;
    background:#fff;
    border-radius: 32px;
    font-weight:500;
    cursor:pointer;
    &:hover{
      background: linear-gradient(270deg, rgb(240, 107, 3) -2%, rgb(234, 39, 224) 100%);
      color:#fff;
    }
  }
}
.hot-wrap{
  .swiper-container{
    .swiper-wrapper{
      margin-bottom: 39px;
      .swiper-slide{
        width: 380px !important;;
      }
    }
    .swiper-pagination{
      .swiper-pagination-bullet{
        height:5px;
        width:20px;
        border-radius: 0;;
      }
    }
  }
}

.more-wrap {
  span{
    cursor:pointer;
    .canhover{
      &:hover{
        opacity: 0.9;
      }
    }
    
  }
}

.playIncon_home{
  width: 20px;
  position: absolute;
  top: 22px;
  left: 22px;
  cursor: pointer;
  display: none;
}
.play_box{
  &:hover{
    cursor:pointer;
    .playIncon_home{
      display:inline-block;
    }
  }
}

  .hot-wrap {
    margin-top: 24px;
  }
  //轮播图
  .card-carousel {
    width:  100%;
    overflow: hidden;
    &:hover{
      .nav__left, .nav__right{
        opacity: 1;
      }
    }
    .card-carousel-container{
      width: 100%;
      display: flex;
      // align-items:center;
      cursor: pointer;
      transition-duration: 0.5s;
      .card-carousel--card{
        cursor: pointer;
        padding:0px 5px;
        img{
          z-index: 1;
        }
      }
      .card-carousel--card:nth-child(last){
        padding-right: 10px;
      }
      .card-carousel--card:nth-child(first){
        padding-left: 10px;
      }
    }
    // 轮播操作
    .nav__left, .nav__right{
      display:inline-block;
      position:relative;
      cursor: pointer;
      width: 38px;
      opacity: 0.2;
    }
    .nav__left{
      float:left;
      margin-left:6%;
    }
    .nav__right{
      float:right;
      margin-right: 6%;
    }
  }
  
  //内容
  .case-wrap {
    margin-top: 27px !important;
    .item {
      width: 10%;
      height: 30px;
      font-size: 22px;
      font-weight: 500;
      color: #333333;
      line-height: 30px;
    }
    .item-list {
      margin-left: 1%;
      width: 13.6%;
      height: 20px;
      font-size: 14px;
      font-weight: 400;
      color: #999999;
      line-height: 20px;
    }
    .partner{
      margin-top: 27px;
    }
    .font-color-base {
      float: right;
      z-index: 10001;
    }
    .song-list {
      display: inline-block;
      margin-left: 170px;

      .items {
        background: #ff7b13;
        color: #fff;
        font-size: 16px;
        text-align: center;
        border: none;
        outline: none;
        box-shadow: none;
        cursor: pointer;
        border: 0;
        margin: 0 12px;
        border-radius: 20px;
        font-size: 13px;
        width: 80px;
        height: 30px;
      }
    }
  }
  //购买
  .home-help-wrap {
    background: #f5f5f7;
    .title, .home-help{
      width: 75%;
      margin: 0 auto;
    }
    .title {
      height: 30px;
      font-size: 22px;
      font-weight: 500;
      color: #333333;
      line-height: 30px;
      padding-top: 54px;
      padding-bottom: 54px;
      .item{
        height: 30px;
        font-size: 22px;
        font-weight: 500;
        color: #333333;
        line-height: 30px;
      }
      .item-list{
        height: 22px;
        font-size: 16px;
        font-weight: 400;
        color: #999999;
        line-height: 22px;
      }
    }
    .home-help {
      display: flex;
      .help-content{
        width: 25%;
        text-align: center;
        .desc{
          margin-top: 28px;
          .numerical, .nameitem{
            height: 25px;
            font-size: 18px;
            font-weight: 500;
            line-height: 25px;
          }
          .numerical{
            color: #FF7B13;
          }
          .nameitem{
            margin-left: 3px;
          }
        }
        .upward{
          width: 60%;
          margin: 0 auto;
          margin-top: 13px;
        }
      }
      .help-content:nth-child(4) {
        padding-left: 2%;
      }
    }
  }
  //合作
  .cooperation {
    width: 100%;
    margin: 0 auto;
    .home-con-img {
      width: 75%;
      margin: 0 auto;
      padding:52px 0px;
      .head-name {
        text-align: center;
        height: 37px;
        font-size: 26px;
        font-weight: 500;
        color: #333333;
        line-height: 37px;
      }
      img {
        width: 100%;
        margin-top: 59px;
      }
    }
  }
  .selectActive {
    background: #ff7b13;
    color:white;
  }
  .selectNoActive{
    background: white;
    color:black;
  }
  .slider-bg{
    border:1px solid #E8E8E8;width:122px; background-color: white;position:absolute;margin-top:6px;
  }
  .slider-cell{
    width:120px;height:40px;position:relative;z-index:1;line-height:40px;text-align:center;font-size:18px;cursor: pointer;
  }
// mobile
@media only screen and (max-width: $screen-width-md) {
  .search-box .info_cate {
    margin-top: 10px;
    padding-bottom: 10px;
    div {
      width: 50%;
      margin: 0;
    }
  }
  .case-wrap .jingxuan .jingxuan_cont .playIncon{
    width: 24px;
    height: 24px;
  }
  .case-wrap .jingxuan .jingxuan_cont .music_info span{
    font-size: 12px;
  }
  .ft14, .ft12{
    font-size: 12px !important;
  }
  .case-wrap .item {
    font-size: 16px;
  }
  .case-wrap .item-list {
    font-size: 12px;
  }
  .more-wrap{
    img{
      width: 20%;
    }
  }
  .new-fine-music-list .music-item .img-all-box-a .lable-wrap .lable-item {
     font-size: 12px;
  }
  .case-wrap .jingxuan .jingxuan_cont {
    width: 33%;
    padding:0;
    overflow: hidden;
    height: 90px;
    padding-top: 1%;
    .music_info {
      width: 100%;
      margin:0;
      span:nth-child(1) {
        font-size: 12px;
      }
      span:nth-child(2) {
        font-size: 12px;
      }
      p{
        font-size: 12px;
      }
    }
  }
  .case-music-list .music-item .music-info .case-desc {
    display: block;
    max-height: 56px;
    overflow: hidden;
  }
  .partner-music-list .music-item .music-info .case-position {
    span{
      width: 100%;
    }
    img {
      width: 11px;
    }
  }
  .company-music-list .music-item .music-info {
    .music-name,  .case-desc{
      font-size: 12px;
    }
  }
  .home-help-wrap .title {
    width: 90%;
    margin: 0 auto;
    .item{
      font-size: 16px;
    }
    .item-list{
      font-size: 12px;
    }
  }

  .home-help-wrap .home-help {
    width: 95%;
    .help-content{
      img{
        width: 80%;
      }
      .desc{
        .numerical, .nameitem{
          font-size: 14px;
        }
      }
      .upward{
        width: 90%;
        font-size: 12px;
      }
    }
  }
  .cooperation .home-con-img {
    width: 90%;
    .head-name {
      font-size: 16px;
    }
  }
  
  
}
</style>
